import React from 'react';

import PageLayout from '../components/Layout';

export default () => (
  <PageLayout>
    <div>
      <h1>castazzo</h1>

      <div>
        <p>coming soon...</p>
      </div>

      <div>
        <p>sign up below to get on the early bird list --></p>
        {/* join the email list */}
      </div>
    </div>
  </PageLayout>
);
